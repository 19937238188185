import React, { Component } from 'react'

import Link from '../utils/link'
import { Close } from '../components/icons'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class Form extends Component {
  state = {
    result: '',
    msg: ''
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.refs.form.checkValidity()) return false

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => {
        this.setState({ result: "success" })
      })
      .catch(error => this.setState({ result: "fail", msg: error }))
  }

  render() {

    let props = {
      ref: "form",
      name: "Newsletter Signup",
      className: "form",
      onSubmit: this.handleSubmit,
      "data-netlify": "true",
      "data-netlify-honeypot": "bot-field",
    }

    if (this.state.result === "success")
      return (
        <p className="popup__success">
          Thanks for subscribing.
        </p>
      )

    return (
      <form netlify {...props}>

        <div className="row">
        <div className="col">

          <div className='col__details'>
            {/* <span>Name</span> */}
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              onChange={this.handleChange}
              required
            />
          </div>

          <div className='col__details'>
          {/* <span>Email</span> */}
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            onChange={this.handleChange}
            required
          />
          </div>
          </div>

          <div className="col">
            <div className="form__checkbox">
              <input
                type="checkbox"
                name="subscribe"
                required="required"
                id="subscribe"
                onChange={this.handleChange}
              />
              <label htmlFor='subscribe'>
              I consent to the <Link to='/legal/'>Terms and Conditions</Link>
              </label>
            </div>
            <div className="btn-container">
              <button type="submit" className="btn">
                Subscribe
              </button>
            </div>

            <span></span>

            {/* <button
              type='button'
              className='popup__close'
              onClick={this.props.hidePopup} // call hidePopup function from prop
            >
              <Close color={'#FFFEFB'}/>
            </button> */}
          </div>
        </div>
      </form>
    )
  }
}

class NewsletterPopup extends Component {

  state = {
    show: false,
    result: '',
    msg: '',
  }

  componentDidMount() {
    const popupDisplayed = sessionStorage.getItem('popupDisplayed')
    // if (!popupDisplayed) {
    //   this.timer = setTimeout(() => {
    //     this.setState({ show: true })
    //     sessionStorage.setItem('popupDisplayed', true)
    //   }, 3000)
    // }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  hidePopup = () => {
    this.setState({ show: false })
    // console.log(this.state)
  }

  render() {
    let { show } = this.state

    return (
      // <div className={show ? 'popup popup__newsletter active' : 'popup popup__newsletter'}>
      //   <div className="popup__wrapper">
      //     <h3>Join our Newsletter</h3>
      //     <div className='popup__header'>
      //     </div>
      //     <div className='popup__form'>
            <Form hidePopup={this.hidePopup} />
      //     </div>
      //   </div>
      // </div>
    )
  }
}

export default NewsletterPopup
