import { useStaticQuery, graphql } from "gatsby"

const useLayoutQuery = () => {
  return useStaticQuery(graphql`
    fragment WpSeo on WpPostTypeSEO {
      metaDesc
      metaKeywords
      metaRobotsNofollow
      opengraphAuthor
      opengraphDescription
      opengraphImage {
        localFile {
          publicURL
        }
      }
      opengraphModifiedTime
      opengraphPublishedTime
      opengraphPublisher
      opengraphSiteName
      opengraphTitle
      opengraphType
      opengraphUrl
      readingTime
      schema {
        articleType
        pageType
        raw
      }
      title
      twitterDescription
      twitterImage {
        localFile {
          publicURL
        }
      }
      twitterTitle
    }
    fragment ArticleCard on WpProject {
      id
      uri
      slug
      title
      date(formatString: "ddd DD MMMM")
      ## date(formatString: "DD/MM/YY")
       acf {
        featureVideo
        vimeoFeatureVideo
        location
        developer
        projectThumbnail {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 2560)
            }
          }
        }
        landscapeFeaturedImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 2560)
            }
          }
        }
      }
    }
    fragment JournalArticleCard on WpPost {
      id
      uri
      slug
      title
      date(formatString: "DD/MM/YY")
      categories {
        nodes {
          name
        }
      }
       acf {
        featureVideo
        vimeoFeatureVideo
        externalLinkSwitch
        externalLinkUrl
          featureImage {
            altText
            localFile {
            childImageSharp {
              gatsbyImageData(width: 2000)
            }
          }
        }
      }
    }
    fragment SketchbookArticleCard on WpSketchbook {
      id
      uri
      slug
      title
      date(formatString: "DD/MM/YY")
      acf {
        fieldGroupName
        featureVideo
        vimeoFeatureVideo
        description
        featuredImage {
          altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2000)
                }
              }
        }
        gallery {
          fieldGroupName
          landscapeImage {
            altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2000)
                }
              }
          }
          imageLayout
          portraitImage {
            altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2000)
                }
              }
          }
          portraitImageAlt {
            altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2000)
                }
              }
          }
        }
      }
    }
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      wp {
        generalSettings {
          title
          url
        }
        siteOptions {
          acf {
            logoColour
            type
            videoEmbed
            landingLargeText
            landingButtonText
            landingButtonUrl
            landingImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 3800)
                }
              }
            }
          }
        }
      }
    }
  `)
}

export default useLayoutQuery