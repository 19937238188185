import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';


//import { useNavigate } from 'react-router-dom'; // Import useNavigate

import { Link } from 'gatsby';
//import Link from '../utils/link';
import { Logo, LogoMark } from '../components/icons';
import SortContext from '../utils/SortContext';

const Header = ({ setSortBy, location }) => {
  const [scrollDirection, setScrollDirection] = useState('none');
  const [scrolled, setScrolled] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [offCanvas, setOffCanvas] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [activeParent, setActiveParent] = useState(null);
  const [selectedSortBy, setSelectedSortBy] = useState('');
  const [activeSubmenuMobile, setActiveSubmenuMobile] = useState('');

  let lastScrollTop = 0; // Declare the variable using 'let'

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrolled = scrollTop >= 50;
    let direction = 'none';

    if (scrollTop >= 100) {
      if (scrollTop > lastScrollTop) {
        direction = 'down';
      } else {
        direction = 'up';
      }
    }

    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;

    setScrollDirection(direction);
    setScrolled(scrolled);
  };

  const handleHeaderHover = () => {
    setIsHovered((prevIsHovered) => !prevIsHovered);
    setScrollDirection('none');
  };

  const handleSubMenuClick = (link) => {
    setActiveSubMenu((prevActiveSubMenu) =>
      prevActiveSubMenu === link ? null : link
    );
    setActiveParent(link === 'contact' ? 'contact' : null);
  };

  const handleLinkClick = (submenu) => {
    if (activeSubmenuMobile === submenu) {
      // If the clicked submenu is already active, close the mobile-submenu
      setActiveSubmenuMobile('');
    } else {
      // If a different submenu is clicked, open that mobile-submenu
      setActiveSubmenuMobile(submenu);
    }
  };

  const hideOffCanvas = () => {
    setOffCanvas(false);
  };

  const toggleOffCanvas = () => {
    setOffCanvas((prevOffCanvas) => !prevOffCanvas);
  };

  // const navigate = useNavigate();

  const handleButtonClick = (value) => {
    setSelectedSortBy(value);
  
    const currentUrl = window.location.pathname;
    const isSinglePressPost =
      currentUrl.startsWith('/press/') && currentUrl !== '/press/';
  
    if (isSinglePressPost) {
      // Handle filter on the single press page without leaving the page
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('sort', value);
      const newUrl = `/press/?${searchParams.toString()}`;
      navigate(newUrl, { replace: true });
    } else {
      // Handle filter on the single press page without leaving the page
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('sort', value);
      const newUrl = `/press/?${searchParams.toString()}`;
      navigate(newUrl, { replace: true });
    }

    hideOffCanvas();
  };

  useEffect(() => {
    let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const headerClass = `header${
    scrollDirection !== 'none' ? ` scrolled-${scrollDirection}` : ''
  }${scrolled ? ' scrolled' : ''}${isHovered ? ' scrolled-up' : ''}`;

  const submenuContainerClass = `header__nav${activeSubMenu ? ' active' : ''}`;


  const currentPath = location.pathname;

  const parentLinkClasses = {
    portfolio: activeParent === 'portfolio' ? 'active' : '',
    about: activeParent === 'about' ? 'active' : '',
    press: currentPath.startsWith('/press') ? 'active' : '', // Use window.location
    sketchbook: currentPath.startsWith('/sketchbook') ? 'active' : '', // Use window.location
    contact: activeParent === 'contact' ? 'active' : '',
  };

  let props = {
    onClick: hideOffCanvas,
    activeClassName: 'active',
  };

  const menuText = offCanvas ? 'Close' : 'Menu';

  return (
    <>
      <header className={headerClass} onMouseEnter={handleHeaderHover} onMouseLeave={handleHeaderHover}>
        <span className={`shadow-overlay ${offCanvas && 'canvas--active'}`}>

        </span> 

        <div className={`header__inner ${offCanvas && 'canvas--active'}`}>
          <Link
            to="/"
            title="Taylor Howes"
            className={`header__logo ${parentLinkClasses.about}`}
            {...props}
            onClick={() => handleSubMenuClick()}
          >
            <span className="logo">
              <Logo color="#1D1D1B" />
            </span>
            <span className="logo-mark">
              <LogoMark color="#1D1D1B" />
            </span>
          </Link>

          <nav className={submenuContainerClass}>
              <ul>
                <li className={parentLinkClasses.portfolio}>
                  <Link
                    to="/portfolio"
                    className={activeSubMenu === 'portfolio' ? 'active' : ''}
                    onClick={() => handleSubMenuClick('portfolio')}
                  >
                    Portfolio
                  </Link>
                  <ul
                    className={
                      activeSubMenu === 'portfolio'
                        ? 'submenu active slideInRight'
                        : 'submenu'
                    }
                  >
                    <li>
                      <Link to="/portfolio/residential" {...props}>
                        Residential
                      </Link>
                    </li>
                    <li>
                      <Link to="/portfolio/hospitality" {...props}>
                        Hospitality
                      </Link>
                    </li>
                    <li>
                      <Link to="/portfolio/developer" {...props}>
                        Developer
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className={parentLinkClasses.about}>
                  <Link
                    to="/about"
                    className={activeSubMenu === 'about' ? 'active' : ''}
                    onClick={() => handleSubMenuClick('about')}
                  >
                    About
                  </Link>
                  <ul
                    className={
                      activeSubMenu === 'about'
                        ? 'submenu active slideInRight'
                        : 'submenu'
                    }
                  >
                    <li>
                      <Link to="/team" {...props}>
                        Team
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className={parentLinkClasses.press}>
                  <Link
                    to="/press/?sort=Press"
                    {...props}
                    onClick={() => handleSubMenuClick('press')}
                  >
                    Press
                  </Link>
                  <ul
                    className={
                      activeSubMenu === 'press'
                        ? 'submenu active slideInRight'
                        : 'submenu'
                    }
                  >
                    <SortContext.Provider value={selectedSortBy}>
                      {/* <li>
                        <button
                          onClick={() => handleButtonClick('all')}
                          className={selectedSortBy === 'all' ? 'active' : ''}
                        >
                          All
                        </button>
                      </li> */}
                      {/* <li>
                        <button
                          onClick={() => handleButtonClick('newest')}
                          className={selectedSortBy === 'newest' ? 'active' : ''}
                        >
                          Newest
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => handleButtonClick('oldest')}
                          className={selectedSortBy === 'oldest' ? 'active' : ''}
                        >
                          Oldest
                        </button>
                      </li> */}
                      {/* <li>
                        <button
                          onClick={() => handleButtonClick('a-z')}
                          className={selectedSortBy === 'a-z' ? 'active' : ''}
                        >
                          A - Z
                        </button>
                      </li> */}
                      <li>
                        <button
                          onClick={() => handleButtonClick('Newsletter')}
                          className={selectedSortBy === 'Newsletter' ? 'active' : ''}
                        >
                          Newsletter
                        </button>
                      </li>
                      <li className='hidden'>
                        <button
                          onClick={() => handleButtonClick('Awards')}
                          className={selectedSortBy === 'Awards' ? 'active' : ''}
                        >
                          Awards
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => handleButtonClick('Podcast')}
                          className={selectedSortBy === 'Podcast' ? 'active' : ''}
                        >
                          Podcast
                        </button>
                      </li>
                    </SortContext.Provider>
                  </ul>
                </li>
                <li className={parentLinkClasses.sketchbook}>
                  <Link
                    to="/sketchbook"
                    {...props}
                    onClick={() => handleSubMenuClick('sketchbook')}
                  >
                    Sketchbook
                  </Link>
                </li>
                <li className={parentLinkClasses.contact}>
                  <Link
                    to="/contact"
                    {...props}
                    onClick={() => handleSubMenuClick('contact')}
                    className={activeSubMenu === 'contact' ? 'active' : ''}
                  >
                    Contact
                  </Link>
                  <ul
                    className={
                      activeSubMenu === 'contact'
                        ? 'submenu active slideInRight'
                        : 'submenu'
                    }
                  >
                    <li>
                      <Link to="/careers" {...props}>
                        Careers
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>

          <div className="header__hamburger-container">
            <button
              onClick={toggleOffCanvas}
              className={offCanvas ? 'header__hamburger active' : 'header__hamburger'}
            >
              {menuText}
            </button>
          </div>
        </div>
      </header>

      <div className={`off-canvas ${offCanvas && 'off-canvas--active'}`}>
        <div className='off-canvas__inner'>
          {/* <button className='off-canvas__menu-close' onClick={hideOffCanvas}>
            <Close color={'#1D1D1B'} />
          </button> */}

            <nav className='off-canvas__nav'>
              <ul>
                <li>
                  <Link
                    to='/portfolio'
                    {...props}
                    onClick={() => handleLinkClick('portfolio')}
                    className={activeSubmenuMobile === 'portfolio' ? 'active' : ''}
                  >
                    Portfolio
                  </Link>
                  <ul className={`mobile-submenu ${activeSubmenuMobile === 'portfolio' ? 'active' : ''}`}>
                    <li><Link to='/portfolio' {...props}>All</Link></li>
                    <li><Link to='/portfolio/residential' {...props}>Residential</Link></li>
                    <li><Link to='/portfolio/hospitality' {...props}>Hospitality</Link></li>
                    <li><Link to='/portfolio/developer' {...props}>Developer</Link></li>
                  </ul>
                </li>
                <li><Link to='/about' {...props}>About</Link></li>
                <li>
                <Link
                    to='/press'
                    {...props}
                    onClick={() => handleLinkClick('press')}
                    className={activeSubmenuMobile === 'press' ? 'active' : ''}
                  >
                    Press
                  </Link>
                  <ul className={`mobile-submenu ${activeSubmenuMobile === 'press' ? 'active' : ''}`}>
                  <SortContext.Provider value={selectedSortBy}>
                      <li>
                        <button
                          onClick={() => handleButtonClick('all')}
                          className={selectedSortBy === 'all' ? 'active' : ''}
                        >
                          All
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => handleButtonClick('Newsletter')}
                          className={selectedSortBy === 'Newsletter' ? 'active' : ''}
                        >
                          Newsletter
                        </button>
                      </li>
                      <li className='hidden'>
                        <button
                          onClick={() => handleButtonClick('Awards')}
                          className={selectedSortBy === 'Awards' ? 'active' : ''}
                        >
                          Awards
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => handleButtonClick('Podcast')}
                          className={selectedSortBy === 'Podcast' ? 'active' : ''}
                        >
                          Podcast
                        </button>
                      </li>
                    </SortContext.Provider>
                  </ul>
                </li>
                <li><Link to='/sketchbook' {...props}>Sketchbook</Link></li>
                <li><Link to='/contact' {...props}>Contact</Link></li>
                <li><Link to='https://studiohowes.co.uk' {...props}>Studio Howes</Link></li>
                <li><Link to='https://howesandlandino.co.uk' {...props}>Howes & Landino</Link></li>
              </ul>
            </nav>
        </div>
      </div>
    </>
  );
};

Header.propTypes = {
  setSortBy: PropTypes.func.isRequired,
};

export default Header;